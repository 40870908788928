import React, {useState, forwardRef, useImperativeHandle, useEffect} from "react";
import {connect} from "react-redux";
import "../../../css/paiement-page.css";
import * as validator from "../../utils/validation";
import {setAlert} from '../actions/alert';
import withNavigation from "../higher-order/navigation";
import Translator from "../translator";
import {showOverlay, hideOverlay} from '../actions/overlay';
import {emptyCard, emptyClient, clientTestCedres, clientCarteTest} from '../info-client/info-client-constants';
import InfoClient from "../info-client/info-client.js";
import InfoClientCarte from "../info-client/info-client-carte.js";
import {DIV_NAME, INPUT_NAME, LABEL_NAME} from "../info-client/info-client-constants";
import {CartItemType, getShop} from "../actions/shop";
import {MandatoryFieldAsterisk} from "../info-client/info-client-field-check";

function getCountryCode(countryName) {
    return countryName === "CANADA"
        ? "CA" : countryName === "ETATS-UNIS"
            ? "US" : "XX";
}

const PaiementForm = forwardRef((props, ref) => {
    const {langue} = props;
    const TEXTS = Translator(langue, "TXT_PAIEMENT_FORM");
    const useClientTest = false; //setter à true pour facilier les données tests

    const [errors, setErrors] = useState({});
    const [infoClient, setInfoClient] = useState(props.infoClient);
    const [infoClientCarte, setInfoClientCarte] = useState(emptyCard);
    const [payAll, setPayAll] = useState(true);
    const montantProduitCampJourInitial = props.shop.cart.reduce((mnt, item) => {
        if (item.type === CartItemType.CampJour) {
            return mnt + item.total;
        }
    }, 0);
    const hasProduitCampJour = props.shop.cart.some(s => s.type === CartItemType.CampJour);
    const [montantProduitCampJour, setMontantProduitCampJour] = useState((Math.round((montantProduitCampJourInitial + Number.EPSILON) * 100) / 100).toFixed(2));

    useImperativeHandle(ref, () => ({
        getInfoClient: () => infoClient,
        getInfoCarte: () => infoClientCarte,
        getPayAll: () => payAll,
        getPaymentAmount: () => hasProduitCampJour ? +(montantProduitCampJour) : payAll ? props.montantTotal : facturePourcentagePaiement1 * props.montantTotal * 0.01,
        setErrors: setErrors,
        validate: validate,
    }));

    useEffect(() => {

        if (props.infoClient) {
            setInfoClient({
                ...infoClient,
                nom: props.infoClient.nom,
                prenom: props.infoClient.prenom,
                telephone1: props.infoClient.telephone1,
                telephone2: props.infoClient.telephone2,
                portable: props.infoClient.portable,
                email: props.infoClient.email,
                emailConfirmation: props.infoClient.email,
                adresse: props.infoClient.adresse,
                ville: props.infoClient.ville,
                codePostal: props.infoClient.codePostal,
            });
        }
    }, [props.infoClient]);
    const {showPermisOrignal, showPermisCerf} = props;
    const hasError = Object.keys(errors).length > 0;
    const hasInfoErrors = hasError && (errors.clientErrors !== undefined);
    const hasCardErrors = hasError && (errors.cardErrors !== undefined);
    const {
        facturePourcentagePaiement1,
        facturePourcentagePaiement2,
        facturePourcentagePaiement3,
        facturePourcentagePaiement4,
        factureNombreJoursEcheancePaiement1,
        factureNombreJoursEcheancePaiement2,
        factureNombreJoursEcheancePaiement3,
        factureNombreJoursEcheancePaiement4
    } = getBillConfig(props.configuration);


    function updateInfoClient(event) {
        const {name, value} = event.target;

        setInfoClient({...infoClient, [name]: value.replace(/[()]/g, '')});
    }

    function updateInfoPays(event) {
        const {selectedOptions, value} = event.target;
        setInfoClient({...infoClient, pays: {idPays: value, description: selectedOptions[0].innerText}});
    }

    function updateIdProvince(newIdProvince) {
        setInfoClient({...infoClient, idProvince: newIdProvince});
    }

    function updateInfoClientCarte(name, value) {
        setInfoClientCarte({...infoClientCarte, [name]: value.replace(/[()]/g, '')});
    }

    function setClientTest() {
        if (props.idZec === 'cedres_test') {
            setInfoClient({...infoClient, ...clientTestCedres});
        }
        setInfoClientCarte({...infoClientCarte, ...clientCarteTest});
    }

    function validate() {
        const clientErrors = {
            nom: infoClient.nom !== "" ? undefined : TEXTS.FIELD_REQUIRED,
            prenom: infoClient.prenom !== "" ? undefined : TEXTS.FIELD_REQUIRED,
            adresse: infoClient.adresse !== "" ? undefined : TEXTS.FIELD_REQUIRED,
            ville: infoClient.ville !== "" ? undefined : TEXTS.FIELD_REQUIRED,
            codePostal: validator.isValidPostalCode(infoClient.codePostal, getCountryCode(infoClient.pays.description)) ? undefined : TEXTS.FIELD_INVALID,
            email: validator.isValidEmail(infoClient.email) ? undefined : TEXTS.FIELD_INVALID,
            emailConfirmation: infoClient.email === infoClient.emailConfirmation ? undefined : TEXTS.FIELD_IDENTICAL,
            telephone1: validator.isValidPhoneNumber(infoClient.telephone1) ? undefined : TEXTS.FIELD_INVALID,
            noPermisOrignal: (showPermisOrignal || showPermisCerf) && (!infoClient.noPermisOrignal || infoClient.noPermisOrignal === "") ? TEXTS.FIELD_REQUIRED : undefined,
            //noPermisCerf: showPermisCerf && (!infoClient.noPermisCerf || infoClient.noPermisCerf === "") ? TEXTS.FIELD_REQUIRED : undefined,
        };

        const cardErrors = { // Validation rudimentaire. Le serveur retourne un message d'erreur si le paiement échoue à cause d'informations de paiement invalides.
            cvd: (infoClientCarte.cvd || "").trim().length < 3 ? TEXTS.FIELD_INVALID : undefined,
            year: (infoClientCarte.expiryYear || "").trim().length < 2 ? TEXTS.FIELD_INVALID : undefined,
            month: (infoClientCarte.expiryMonth || "").trim().length < 2 ? TEXTS.FIELD_INVALID : undefined,
            name: (infoClientCarte.name || "").trim().length === 0 ? TEXTS.FIELD_INVALID : undefined,
            number: (infoClientCarte.number || "").trim().length !== 15 && (infoClientCarte.number || "").trim().length !== 16 ? TEXTS.FIELD_INVALID : undefined,
        };

        setErrors({clientErrors, cardErrors});

        return {
            isInfoClientValid: Object.values(clientErrors).filter(v => v !== undefined).length === 0,
            isInfoCarteValid: Object.values(cardErrors).filter(v => v !== undefined).length === 0,
        };
    }

    const peutFairePaiementsMultiples = !props.disablePaiementsMultiples
        && facturePourcentagePaiement1 > 0 && facturePourcentagePaiement1 < 100 &&
        (facturePourcentagePaiement2 > 0 ||
            facturePourcentagePaiement3 > 0 ||
            facturePourcentagePaiement4 > 0 ||
            factureNombreJoursEcheancePaiement1 > 0 ||
            factureNombreJoursEcheancePaiement2 > 0 ||
            factureNombreJoursEcheancePaiement3 > 0 ||
            factureNombreJoursEcheancePaiement4 > 0);

    const showFraisAdmin = props.montantFraisAdmin != null && props.montantFraisAdmin > 0;

    return (
        <div className="paiement-form" ref={ ref }>
            { props.showInfoClient && <InfoClient
                lang={ langue }
                errors={ hasInfoErrors ? errors.clientErrors : {} }
                idZec={ props.idZec }
                infos={ infoClient }
                updateInfo={ updateInfoClient }
                updateInfoPays={ updateInfoPays }
                updateIdProvince={ updateIdProvince }
                showPermisOrignal={ showPermisOrignal }
                showPermisCerf={ showPermisCerf }
            /> }
            { props.showInfoClient && <div className="paiement-page-separator"/> }
            <InfoClientCarte
                lang={ langue }
                errors={ hasCardErrors ? errors.cardErrors : {} }
                infos={ infoClientCarte }
                updateInfo={ updateInfoClientCarte }/>
            <div className="paiement-page-separator" style={ {marginBottom: '8px'} }/>
            { hasProduitCampJour ?
                <>
                    <div className={ "info-client-field" }>
                        <label className={ "info-client-field-label" }>MONTANT DU PAIEMENT (MINIMUM { Math.round((montantProduitCampJourInitial * 0.3 + Number.EPSILON) * 100) / 100 }$): { <MandatoryFieldAsterisk/> }</label>
                        <input
                            className={ "info-client-field-input" }
                            name="montantDu" type="text" value={ montantProduitCampJour } onChange={ (e) => setMontantProduitCampJour(e.target.value) } onBlur={ (e) => setMontantProduitCampJour((Math.round((Math.min(montantProduitCampJourInitial, e.target.value) + Number.EPSILON) * 100) / 100).toFixed(2)) } required/>
                    </div>
                    { props.showInfoClient && <div className="paiement-page-separator"/> }
                </>
                : null }
            { payAll &&
                <div>
                    { props.montantSousTotal != null && showFraisAdmin &&
                        <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ TEXTS.SOUS_TOTAL }</label>
                            <span>{ props.montantSousTotal.toFixed(2) + "$" }</span>
                        </div>
                    }
                    { showFraisAdmin &&
                        <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ TEXTS.FRAIS_ADMIN }</label>
                            <span>{ props.montantFraisAdmin.toFixed(2) + "$" }</span>
                        </div>
                    }
                    { showFraisAdmin &&
                        <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ TEXTS.FRAIS_ADMIN_TPS }</label>
                            <span>{ props.montantFraisAdminTps.toFixed(2) + "$" }</span>
                        </div>
                    }
                    { showFraisAdmin &&
                        <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ TEXTS.FRAIS_ADMIN_TVQ }</label>
                            <span>{ props.montantFraisAdminTvq.toFixed(2) + "$" }</span>
                        </div>
                    }
                    <div className={ DIV_NAME + " montant-row" }>
                        <label className={ LABEL_NAME }>{ TEXTS.MONTANT }</label>
                        <span>{ props.montantTotal.toFixed(2) + "$" }</span>
                    </div>
                </div>
            }
            { peutFairePaiementsMultiples &&
                <div>
                    { payAll || <div>
                        <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ factureNombreJoursEcheancePaiement1 == 0 ? "MONTANT À PAYER MAINTENANT : " : "MONTANT À PAYER AVANT " + factureNombreJoursEcheancePaiement1 + " JOURS : " }</label>
                            <span>{ (facturePourcentagePaiement1 * props.montantTotal * 0.01).toFixed(2) + "$" }</span>
                        </div>
                        { factureNombreJoursEcheancePaiement1 === 100 || <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ "MONTANT À PAYER AVANT " + factureNombreJoursEcheancePaiement2 + " JOURS : " }</label>
                            <span>{ (facturePourcentagePaiement2 * props.montantTotal * 0.01).toFixed(2) + "$" }</span>
                        </div> }
                        { factureNombreJoursEcheancePaiement1 + factureNombreJoursEcheancePaiement2 === 100 || <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ "MONTANT À PAYER AVANT " + factureNombreJoursEcheancePaiement3 + " JOURS : " }</label>
                            <span>{ (facturePourcentagePaiement3 * props.montantTotal * 0.01).toFixed(2) + "$" }</span>
                        </div> }
                        { factureNombreJoursEcheancePaiement1 + factureNombreJoursEcheancePaiement2 + factureNombreJoursEcheancePaiement3 === 100 || <div className={ DIV_NAME + " montant-row" }>
                            <label className={ LABEL_NAME }>{ "MONTANT À PAYER AVANT " + factureNombreJoursEcheancePaiement4 + " JOURS : " }</label>
                            <span>{ (facturePourcentagePaiement4 * props.montantTotal * 0.01).toFixed(2) + "$" }</span>
                        </div> }
                    </div> }
                    <div className={ DIV_NAME }>
                        <label className={ LABEL_NAME }>{ TEXTS.TOUT_PAYER }</label>
                        <input type="checkbox" checked={ payAll } onClick={ e => setPayAll(true) } readOnly/>
                    </div>
                    <div className={ DIV_NAME }>
                        <label className={ LABEL_NAME }>{ TEXTS.SOUS_PAYER }</label>
                        <input type="checkbox" checked={ !payAll } onClick={ e => setPayAll(false) } readOnly/>
                    </div>
                </div>
            }
            { useClientTest &&
                <div className="paiement-submit-button" onClick={ setClientTest }>
                    <span>CLIENT TEST</span>
                </div> }
        </div>
    );
})

function getBillConfig(configuration) {
    let {
        facturePourcentagePaiement1,
        facturePourcentagePaiement2,
        facturePourcentagePaiement3,
        facturePourcentagePaiement4,
        factureNombreJoursEcheancePaiement1,
        factureNombreJoursEcheancePaiement2,
        factureNombreJoursEcheancePaiement3,
        factureNombreJoursEcheancePaiement4
    } = configuration;
    return {
        facturePourcentagePaiement1: facturePourcentagePaiement1 > 0 ? Number(facturePourcentagePaiement1) : 0,
        facturePourcentagePaiement2: facturePourcentagePaiement2 > 0 ? Number(facturePourcentagePaiement2) : 0,
        facturePourcentagePaiement3: facturePourcentagePaiement3 > 0 ? Number(facturePourcentagePaiement3) : 0,
        facturePourcentagePaiement4: facturePourcentagePaiement4 > 0 ? Number(facturePourcentagePaiement4) : 0,
        factureNombreJoursEcheancePaiement1: factureNombreJoursEcheancePaiement1 > 0 ? Number(factureNombreJoursEcheancePaiement1) : 0,
        factureNombreJoursEcheancePaiement2: factureNombreJoursEcheancePaiement2 > 0 ? Number(factureNombreJoursEcheancePaiement2) : 0,
        factureNombreJoursEcheancePaiement3: factureNombreJoursEcheancePaiement3 > 0 ? Number(factureNombreJoursEcheancePaiement3) : 0,
        factureNombreJoursEcheancePaiement4: factureNombreJoursEcheancePaiement4 > 0 ? Number(factureNombreJoursEcheancePaiement4) : 0
    }
}

const mapStateToProps = (state) => ({
    authToken: state.authToken,
    infoClient: state.infoClient,
    configuration: state.configuration,
    shop: state.shop
});

export default connect(mapStateToProps, {getShop}, null, {forwardRef: true})(PaiementForm);
