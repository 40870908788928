import React, {
    useState, useEffect
} from 'react';
import {
    useParams
} from 'react-router-dom';
import {
    connect
} from "react-redux";
import withNavigation from "../higher-order/navigation";
import Translator from "../translator";
import "./billet-unite.css";
import {
    getBilletterie, getQuantiteEnMain
} from "../../server/services/catalogue-service";
import {
    getTrancheAge
} from "../../server/services/trancheage";
import SommaireRightPanel from "../achat-forfait/sommaire-right-panel";
import {
    getShop
} from '../actions/shop';
import {
    PATH_PANIER
} from '../../constants/navigation-constants';
import {
    addToShoppingCart, updateShop, CartItemType, setErrorAlert
} from '../actions/shop';
import {
    useSeoTitle
} from "../../utils/hooks";
import CartSvg from '../svg/cart';
import PreviousSvg from '../svg/previous';
import {
    Fragment
} from 'react';
import {
    addBilleterieTemp, getQuantiteTempByIdProduit
} from "../../server/services/reservation-service";
import DemanderTrancheAgeBilleterie from "./demander-trancheage-billetterie-popup";

function BilletUnite(props) {
    const TEXTS = Translator(props.match.params.language, "TXT_BILLET_UNITE");
    useSeoTitle(TEXTS.SEO_TITLE);

    const [billet, setBillet] = useState({});
    const [fields, setFields] = useState([]);
    const [prices, setPrices] = useState([]);
    const [idProduitsPrix, setIdProduitPrix] = useState([]);
    const [isPopupTrancheAgeOpen, setPopupTrancheAgeOpen] = useState(false);
    const [trancheAges, setTrancheAges] = useState([]);
    let {idBillet} = useParams();

    useEffect(async () => {
        let fields = {}
        let prices = {}
        let ids = {};
        if (props.location.state) {
            // Billet imported from billetterie page
            setBillet(props.location.state)
            props.location.state.produitPrix.map(cur => {
                fields[cur.description] = 0;
                prices[cur.description] = cur.prix;
                ids[cur.description] = cur.idProduitPrix;
            })
            if (props.location.state.options) { // Si modifie a partir du PANIER
                fields = props.location.state.options;
            }
        } else if (idBillet) {
            getBilletterie(idBillet)
                .then(function (json) {
                    if (json.length) {
                        // Billet fetched by Id
                        setBillet(json[0])
                        json[0].produitPrix.map(cur => {
                            fields[cur.description] = 0;
                            prices[cur.description] = cur.prix;
                            ids[cur.description] = cur.idProduitPrix;
                        })
                    }
                })
        }
        setFields(fields)
        setPrices(prices)
        setIdProduitPrix(ids);
        await setTrancheAges((await getTrancheAge()).map((tranche) => {
            return {
                ...tranche,
                quantite: 0
            }
        }));
    }, [])

    const checkSiFamilliale = async (item) => {
        const hasDetailFamilial = item.produitPrix.some((itemDetailPrix) => fields[itemDetailPrix.description] > 0 && itemDetailPrix.forfaitFamilial);
        if (hasDetailFamilial)
            setPopupTrancheAgeOpen(true);
        else
            add(item);
    };

    const handleClosePopupTrancheAgeFamilial = async () => {
        await setPopupTrancheAgeOpen(false);
    }

    const onConfirmationPopupTrancheAgeFamilial = async () => {
        await add(billet);
    }

    const onValueChangeTrancheAgePopup = async (trancheAge) => {
        await setTrancheAges(trancheAge);
    }

    const add = async (item) => {
        const newItem = {};
        let montant = 0;
        let quantiteProducts = 0;
        newItem["inclus"] = [];
        newItem["idsProduitPrix"] = [];
        Object.keys(fields).map((cur, index) => {
            if (fields[cur] > 0) {
                newItem["inclus"].push({
                    name: `Billet(s) ${ cur }: ${ fields[cur] }x ${ prices[cur] }$`
                });
                newItem["idsProduitPrix"].push({
                    id: idProduitsPrix[cur],
                    quantite: fields[cur],
                    familliale: false
                })
                montant += fields[cur] * prices[cur];

                quantiteProducts += parseInt(fields[cur]);
            }
        });
        const qteMain = await getQuantiteEnMain(item.idProduit) - await getQuantiteTempByIdProduit(item.idProduit) - quantiteProducts;

        if (qteMain < 0) {
            props.setErrorAlert(qteMain + quantiteProducts <= 0
                ? TEXTS.IS_FULL
                : `${ TEXTS.IS_PARTIALLY_FULL_0 } ${ Math.max(0, qteMain + quantiteProducts) } ${ TEXTS.IS_PARTIALLY_FULL_1 }`, 'danger'
            );
            return;
        }
        if (newItem["inclus"].length == 0) {
            props.setErrorAlert(TEXTS.EMPTY_INPUT, 'danger');
            return;
        }
        //if (window.confirm("Confirmez ?")) {
        newItem["name"] = item.codeProduit;
        newItem["montant"] = montant;
        newItem["options"] = fields;
        newItem["taxableTps"] = item.taxeTps;
        newItem["taxableTvq"] = item.taxeTvq;
        if (item.options) {
            newItem["montantOriginal"] = item.montant;
            props.updateShop(item.index, newItem);
            props.history.replace() // Nécessaire s'il back de l'historique
            props.goTo(PATH_PANIER)
        } else {
            // newItem["image"] = "data:image/jpeg;base64," + this.state.image;
            newItem["idProduit"] = item.idProduit;
            newItem["codeProduit"] = item.codeProduit;
            newItem["produitPrix"] = item.produitPrix;
            newItem["image"] = item.image;
            newItem["descriptionWebFrancaise"] = item.descriptionWebFrancaise;
            newItem["type"] = CartItemType.Billetterie;
            const result = await addBilleterieTemp({
                idProduit: item.idProduit,
                quantite: quantiteProducts
            });
            newItem["idsReservationTemp"] = [result];
            newItem["tranchesAge"] = trancheAges;
            props.addToShoppingCart(newItem, true, props.match.params.language);
        }
        //}
    }

    const handleChange = (field, e) => {
        if (e.target.value > 99 || e.target.value < 0) return
        const value = e.target.value;
        setFields({
            ...fields,
            [field]: value
        });
    }

    const thumbnail = billet.produitImage == null || billet.produitImage.length === 0 ? require("../../../images/no-image.png") : "data:image/png;base64," + billet.produitImage[0].image;

    return (
        <Fragment>
            <DemanderTrancheAgeBilleterie maxAdulte={ (billet.produitPrix || []).reduce((nbAdultesFamillial, item) => parseInt(nbAdultesFamillial) + parseInt(item.forfaitFamilial ? fields[item.description] : 0), 0) } trancheAges={ trancheAges } isOpen={ isPopupTrancheAgeOpen } onValueChange={ onValueChangeTrancheAgePopup } handleClose={ handleClosePopupTrancheAgeFamilial } onConfirmation={ onConfirmationPopupTrancheAgeFamilial }/>

            <div className="billet-unite-achat-btn unselectable billetterie-page-retour-btn">
        <span className="clickable" onClick={ () => props.goTo("/billetterie") }>
          <PreviousSvg fill='rgb(255, 103, 76, 1)' style={ {
              position: 'relative',
              top: '10px'
          } } width="26" height="26"/>
            { TEXTS.RETURN }
        </span>
            </div>
            <div id="billetterie-page-container">
                <div className="recherche-component billeterie-div-filter"/>
                <div className="achat-forfait-card-container">
                    { billet && billet != '' ?
                        <div className="card-sub-container">
                            <div className="card-title achat-forfait-tab">
                                <span>{ billet.description }</span>
                            </div>
                            <table>
                                <tbody>
                                <tr className='billet-unite-ul'>
                                    <td className="col1">
                                        <div className="billet-column medium-3">
                                            <img src={ thumbnail } alt=""/>
                                        </div>
                                    </td>
                                    <td className="col2">
                                        <div className="billet-column medium-9">
                                            <div className="description">
                                                <div className="billet-quantite-wrapper">
                                                    { billet.produitPrix && billet.produitPrix.map((cur, key) => {
                                                        return <div className="billet-quantite" key={ key }>
                                                            <span>{ cur.description }</span><br/>
                                                            <strong>{ cur.prix } $</strong><br/>
                                                            <input className='billet-unite-quantity' value={ fields[cur.description] || '' } type="number" id={ cur.description }
                                                                   name="quantity" min="0" max="99" pattern="^-?[0-9]\d*\.?\d*$"
                                                                   onChange={ handleChange.bind(this, cur.description) }
                                                            />
                                                        </div>
                                                    }) }
                                                </div>
                                                <div className="colonne-auto-right">
                                                    <div className="billet-unite-achat-btn unselectable" onClick={ () => checkSiFamilliale(billet) }>
                              <span className="clickable">
                                { billet.options ? TEXTS.MODIFIER : TEXTS.AJOUTER }
                                  <CartSvg fill='rgb(255, 103, 76, 1)' style={ {
                                      position: 'relative',
                                      top: '7px'
                                  } } width="26" height="26"/>
                              </span>
                                                    </div>
                                                </div>
                                                <div className="clear">&nbsp;</div>
                                                <hr/>
                                                <div className="content-description">
                                                    <p className="text-left large-text-justify">
                                                        { billet.descriptionWebFrancaise && billet.descriptionWebFrancaise.split("\n").map((item, key) => (
                                                            <span key={ key }>{ item }<br/></span>)) }
                                                    </p>
                                                </div>
                                                <hr/>
                                                <h3 className="text-center">{ TEXTS.SHARE }</h3>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        : <div><h2>{ TEXTS.NON_DISPONIBLE }</h2></div>
                    }
                </div>
                <div className="sommaire-right-panel max-width sommaire-large-screen">
                    <SommaireRightPanel
                        allowDelete={ false }
                        message="FACTURE"
                        confirm={ () => props.goTo("/panier", "PANIER") }
                        confirmTitle={ TEXTS.CONFIRM_TITLE }
                        confirmError={ TEXTS.CONFIRM_ERROR }
                        showByType={ CartItemType.Billetterie }
                        canConfirm={ props.shop.cart.length > 0 }
                    />
                </div>
            </div>

        </Fragment>);
}

const mapStateToProps = (state, ownProps) => ({
    shop: state.shop,
    entrepriseName: state.entreprise.nom
});

const dispatchProps = {
    getShop,
    addToShoppingCart,
    updateShop,
    setErrorAlert
};

export default withNavigation(connect(mapStateToProps, dispatchProps)(BilletUnite));