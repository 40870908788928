import React from "react";
import "../../../css/info-client.css";
import Translator from "../translator";
import InfoAdresseInput from './info-client-adresse-input';
import InfoPays from './info-client-pays';
import InfoProvince from './info-client-province';
import InfoEmail from './info-client-email';
import InfoName from './info-client-name';
import InfoPhone from './info-client-phone';

function InfoClient(props) {
    const TEXTS = Translator(props.lang, "TXT_INFO_CLIENT");
    const {infos, showPermisOrignal, showPermisCerf} = props;
    const isCanada = infos.pays.description === 'CANADA';
    const isUsaOrCanada = !infos.pays.description || isCanada || infos.pays.description === 'ETATS-UNIS';
    const isEspaceTonik = props.idZec === 'espacetonik';
    const hasError = Object.keys(props.errors).length > 0;

    function getError(info) {
        return hasError ? props.errors[info] : '';
    }

    return (
        <div id="info-client">
            <InfoName
                error={ getError('prenom') }
                name="prenom"
                label={ TEXTS.PRENOM }
                value={ infos.prenom }
                onChange={ props.updateInfo }/>
            <InfoName
                error={ getError('nom') }
                name="nom"
                label={ TEXTS.NOM }
                value={ infos.nom }
                onChange={ props.updateInfo }/>
            <InfoAdresseInput
                error={ getError('adresse') }
                name="adresse"
                label={ TEXTS.ADRESSE }
                length="100"
                value={ infos.adresse }
                onChange={ props.updateInfo }/>
            <InfoAdresseInput
                error={ getError('ville') }
                length="100"
                name="ville"
                label={ TEXTS.VILLE }
                required
                value={ infos.ville }
                onChange={ props.updateInfo }/>
            <InfoPays
                name="idPays"
                label={ TEXTS.PAYS }
                onChange={ props.updateInfoPays }
                value={ infos.pays.idPays }/>
            { isUsaOrCanada &&
                <InfoProvince
                    error={ getError('idProvince') }
                    isCanada={ isCanada }
                    idPays={ infos.pays.idPays }
                    isUsaOrCanada={ isUsaOrCanada }
                    name="idProvince"
                    label={ TEXTS.PROVINCE }
                    value={ infos.idProvince }
                    onChange={ props.updateInfo }
                    updateIdProvince={ props.updateIdProvince }/>
            }
            <InfoAdresseInput
                error={ getError('codePostal') }
                name="codePostal"
                label={ TEXTS.CODE_POSTAL }
                pays={ infos.pays.description }
                value={ infos.codePostal }
                onChange={ props.updateInfo }/>
            <InfoEmail
                error={ getError('email') }
                name="email"
                label={ TEXTS.EMAIL }
                value={ infos.email }
                onChange={ props.updateInfo }/>
            <InfoEmail
                error={ getError('emailConfirmation') }
                name="emailConfirmation"
                label={ TEXTS.EMAIL_CONFIRM }
                value={ infos.emailConfirmation }
                onChange={ props.updateInfo }/>
            <InfoPhone
                error={ getError('telephone1') }
                name="telephone1"
                label={ isEspaceTonik ? TEXTS.TELEPHONE1_TONIK : TEXTS.TELEPHONE1 }
                value={ infos.telephone1 }
                onChange={ props.updateInfo }/>
            { !isEspaceTonik &&
                <InfoPhone
                    error={ getError('telephone2') }
                    name="telephone2"
                    label={ TEXTS.TELEPHONE2 }
                    required
                    value={ infos.telephone2 }
                    onChange={ props.updateInfo }/>
            }
            <InfoPhone
                error={ getError('portable') }
                label="TELEPHONE CELLULAIRE :"
                name="portable"
                label={ isEspaceTonik ? TEXTS.PORTABLE_TONIK : TEXTS.PORTABLE }
                value={ infos.portable }
                onChange={ props.updateInfo }/>
            { (showPermisOrignal || showPermisCerf) && <InfoName
                error={ getError('noPermisOrignal') }
                name="noPermisOrignal"
                label={ "# certificat de chasse" }
                value={ infos.noPermis }
                onChange={ props.updateInfo }/> }
            {/* {showPermisCerf && <InfoName
                error={getError('noPermisCerf')}
                name="noPermisCerf"
                label={"# certificat de chasse"}
                value={infos.noPermis}
                onChange={props.updateInfo}/>}*/ }
        </div>
    );
}

// champs requis
export default InfoClient;