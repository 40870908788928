import {setAlert} from "./alert";
import {RESERVATION_TEMP_TIMEOUT} from "../../constants/reservation-constants";
import {
    calculateSumarryAmounts, deleteReservationTemp, updateReservationTempTimeout
} from "../../server/services/reservation-service";
import SessionKeys from "../../session";
import {
    GET_SHOP, ADD_TO_SHOPPING_CART, REMOVE_FROM_SHOPPING_CART, UPDATE_SHOP, // CLEAR_SHOP,
    SHOP_ERROR, SET_FRAIS_ADMIN, SET_TAUX_TAXES, CLEAR_TIMEOUT_SHOP, INITIALIZE_SHOPPING_CART, UPDATE_PROMOTIONS, INITIALIZE_PROMOTIONS, REMOVE_PROMOTION, RECALCULER_SHOP, CLEAR_PROMOTION, UPDATE_MONTANT_DEPOT
} from "./types";
import {api} from "../../server/api";
import {hidePopup} from "./generic-popup";
import {setReload} from "./form-pop-active";
import {parseJSON} from "date-fns";

export const CartItemType = {
    // Nommé selon la naming convention de Google pour les énumérations: https://google.github.io/styleguide/javascriptguide.xml?showone=Naming#Naming
    Forfait: "forfait", ReservationActivite: "Reservation d'activité", ReservationCamping: "Reservation Camping", ReservationHebergement: "Reservation Hebergement", CommandeRestaurant: "Commande restaurant", Billetterie: "Billet", Tirage: 'Tirage', CampJour: 'CampJour', Activite: 'Activite', Boutique: "boutique"
};

const TPS = 0.05;
const TVQ = 0.09975;

/**Time out pour la mise à jour de l'expiration dans la table temporaire */
var timeoutId;

function roundTwoDecimals(n) {
    return Math.round(n * 100) / 100;
}

// Get shop
export const getShop = () => async (dispatch) => {
    try {
        dispatch({
            type: GET_SHOP
        });
    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.response.statusText, status: err.response.status
            }
        });
        dispatch(setAlert(err, "danger"));
    }
};

export const initializeShoppingCart = (payload) => async (dispatch, getState) => {
    try {
        const {cart: cart, promotions} = payload;
        let idsRrsvTemp = [];
        let hasReservation = false;

        if (cart && cart !== null) {
            for (let i = 0; i < cart.length; i++) {
                const cartItem = cart[i];

                if (cartItem.type && cartItem.type.includes("Reservation")) {
                    // Quels types de cart items ont 'Reservation' à part 'Reservation d'activité'?
                    idsRrsvTemp = idsRrsvTemp.concat(cartItem.idsReservationTemp);
                    hasReservation = true;
                }
                if (cartItem.selectedDate) cartItem.selectedDate = new Date(cartItem.selectedDate);
            }
        }

        dispatch(setTimeoutShop(idsRrsvTemp));
        if (hasReservation) {
            const currentState = getState();
            api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: promotions || [], cart: cart || []}})).then(depot => {
                const montantDepot = cart?.reduce((montantDepotAutre, curItem) => {
                    if (!curItem.type.includes("Reservation")) {
                        return montantDepotAutre + curItem["total"] || 0
                    }
                    return montantDepotAutre;
                }, 0)
                dispatch({
                    type: UPDATE_MONTANT_DEPOT, payload: {
                        ...depot, montant: depot.montant + montantDepot || 0
                    }
                })
            });
        } else {
            const montantDepot = cart?.reduce((montantDepotAutre, curItem) => {
                if (!curItem.type.includes("Reservation")) {
                    return montantDepotAutre + curItem["total"] || 0
                }
                return montantDepotAutre;
            }, 0)
            dispatch({
                type: UPDATE_MONTANT_DEPOT, payload: {
                    montant: montantDepot || 0
                }
            })
        }


        dispatch({
            type: INITIALIZE_SHOPPING_CART, payload: {
                cart: cart || [], promotions: promotions || []
            }
        })
    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.response.statusText || err, status: err.response.status
            }
        });
    }
};

// Add an item to the shopping cart
export const addToShoppingCart = (newItem, showAlert = true, lang = 'fr') => async (dispatch, getState) => {
    //console.log(newItem);
    const {shop} = getState();
    try {
        let depot = 0;
        //newItem['tps'] = (newItem['montant'] * TPS); // Certain clients ne paie pas de tps/tvq, certains produits ne sont pas taxés, et certains produits ont les taxes inclusent. Pour cette raison, on ne peut pas calculer les taxes en multipliant le montant par le taux de taxe.
        //newItem['tvq'] = (newItem['montant'] * TVQ); // Certain clients ne paie pas de tps/tvq, certains produits ne sont pas taxés, et certains produits ont les taxes inclusent. Pour cette raison, on ne peut pas calculer les taxes en multipliant le montant par le taux de taxe.
        //newItem['total'] = (Number(newItem['montant']) + Number(newItem['tps']) + Number(newItem['tvq']));
        if (newItem["type"].includes("Reservation")) {

            if (!newItem["idsReservationTemp"]) {
                if (lang === 'en') {
                    await dispatch(setAlert("An unexpected error has occurred that has prevented the selected unite from being added to your cart, please try again.", "danger"));
                } else {
                    await dispatch(setAlert("Une erreur inattendue s'est produite et a empêché l'ajout de l'unité sélectionné dans votre panier, veuillez réessayer.", "danger"));
                }
                throw new Error("Une erreur s'est produite lors de l'ajout au panier, veuillez réessayer.");
            }

            if (shop.cart.filter(f => f.type.includes("Reservation")).some(s => {
                const dateDebutItem = parseJSON(s.selectedUnite.selectedArrivee);
                const dateFinItem = parseJSON(s.selectedUnite.selectedDepart);
                const dateDebutNewItem = parseJSON(newItem.selectedUnite.selectedArrivee);
                const dateFinNewItem = parseJSON(newItem.selectedUnite.selectedDepart);

                return s.type.includes("Reservation") && (dateFinNewItem < dateDebutItem || dateDebutNewItem > dateFinItem)
            })) {
                if (lang === 'en') {
                    await dispatch(setAlert("You cannot book for 2 separate stays, first complete the stay already in the cart", "danger"));

                } else {
                    await dispatch(setAlert("Vous ne pouvez pas réserver pour 2 séjours distincts, complété votre réservation au panier", "danger"));
                }
                throw new Error("Vous ne pouvez pas réserver pour 2 séjours distincts, complété votre réservation au panier");
            }

            // Quels types de cart items ont 'Reservation' à part 'Reservation d'activité'?
            var montants = await calculateSumarryAmounts(newItem["selectedUnite"]);

            if (montants.montantDetailles.some(mntDetail => mntDetail.description === "Invalide")) throw new Error(lang === 'en' ? "Error when calculating the rate" : 'Erreur lors du calcul du tarif')

            if (Object.keys(montants).length === 0) {
                newItem["fraisAdmin"] = 0;
                newItem["hebergement"] = 0;
                newItem["tps"] = newItem["montant"] * TPS;
                newItem["tvq"] = newItem["montant"] * TVQ;
                newItem["total"] = Number(newItem["montant"]) + Number(newItem["tps"]) + Number(newItem["tvq"]);
                newItem["montantDepots"] = newItem["total"];
                depot += newItem["total"];
            } else {

                newItem["montant"] = montants.montantAvantTaxes;
                newItem["fraisAdmin"] = montants.fraisAdmin;
                newItem["hebergement"] = montants.hebergement;
                newItem["tps"] = montants.tps;
                newItem["tvq"] = montants.tvq;
                newItem["total"] = montants.total;
                /*const montantDepot = montants.montantDepots.find((mntDepot) => mntDepot.noDepot === 1);
                newItem["montantDepots"] = montantDepot ? montantDepot.montant : montants.total;
                newItem["isMontantDepotFix"] = montantDepot ? montantDepot.is_depot_fix : false;*/
                newItem["montantDetail"] = montants.montantDetailles;
            }
            //effacer les donnees du pop fom activite
            if (newItem['selectedUnite']?.type_hebergement == 'AC') {
                await dispatch(hidePopup())
            }
            var currentState = getState();
            //la condition est juste pour permettre l'ajout des activites dans le panier cela doit disparaitre une fois que les element de l'activite sont OK
            if (!(newItem['selectedUnite']?.type_hebergement === 'AC')) {
                const promotionValides = await api.post("promotion/getPromotionApplicableAuto", JSON.stringify({shop: currentState.shop}))
                if (Array.isArray(promotionValides)) {
                    await promotionValides.forEach((promotionValide) => {
                        dispatch(updatePromotions(promotionValide, false));
                    });
                }
            }
        } else if (newItem["type"] === CartItemType.CampJour) {

            if (newItem["tps"] === undefined || newItem["tvq"] === undefined || newItem["total"] === undefined || newItem["montantDepots"] === undefined || newItem["hebergement"] === undefined || newItem["fraisAdmin"] === 0) {
                // Bloc temporaire en attendant que le site calcule les taxes correctement partout
                newItem["tps"] = newItem["taxableTps"] || newItem["taxableTps"] === undefined ? newItem["montant"] * TPS : 0;
                //newItem['tvq'] = roundTwoDecimals(newItem['montant'] * TVQ + newItem['montant'] * TPS - newItem['tps']); // Ce "+ newItem['montant'] * TPS - newItem['tps']" corrige les erreurs d'arrondissement de cents (e.g. round(3.333 + 3.333) = 6.67 mais round(3.33) + round(3.33) = 6.66. Le Manisoft met la cet supplémentaire dans la TVQ).
                newItem["tvq"] = newItem["taxableTvq"] || newItem["taxableTvq"] === undefined ? newItem["montant"] * TVQ : 0; // Corriger l'arrondissement de cents seulement à la fin lors du calcul du total
                newItem["total"] = Math.round((Number(newItem["montant"]) + Number(newItem["tps"]) + Number(newItem["tvq"]) + Number.EPSILON) * 100) / 100;
                newItem["fraisAdmin"] = 0;
                newItem["hebergement"] = 0;
                newItem["montantDepots"] = newItem["total"];
                depot += newItem["total"];
            }
        } else {
            if (newItem["tps"] === undefined || newItem["tvq"] === undefined || newItem["total"] === undefined || newItem["montantDepots"] === undefined || newItem["hebergement"] === undefined || newItem["fraisAdmin"] === 0) {
                // Bloc temporaire en attendant que le site calcule les taxes correctement partout
                newItem["tps"] = newItem["taxableTps"] || newItem["taxableTps"] === undefined ? newItem["montant"] * TPS : 0;
                //newItem['tvq'] = roundTwoDecimals(newItem['montant'] * TVQ + newItem['montant'] * TPS - newItem['tps']); // Ce "+ newItem['montant'] * TPS - newItem['tps']" corrige les erreurs d'arrondissement de cents (e.g. round(3.333 + 3.333) = 6.67 mais round(3.33) + round(3.33) = 6.66. Le Manisoft met la cet supplémentaire dans la TVQ).
                newItem["tvq"] = newItem["taxableTvq"] || newItem["taxableTvq"] === undefined ? newItem["montant"] * TVQ : 0; // Corriger l'arrondissement de cents seulement à la fin lors du calcul du total
                newItem["total"] = Math.round((Number(newItem["montant"]) + Number(newItem["tps"]) + Number(newItem["tvq"]) + Number.EPSILON) * 100) / 100;
                newItem["fraisAdmin"] = 0;
                newItem["hebergement"] = 0;
                newItem["montantDepots"] = newItem["total"];
                depot += newItem["total"];
            }
        }

        if (showAlert) {
            if (lang === 'en') dispatch(setAlert(newItem.name + " added to the shopping cart", "success")); else dispatch(setAlert(newItem.name + " a été ajouté au panier", "success"));
        }
        await dispatch({
            type: ADD_TO_SHOPPING_CART, payload: newItem
        });
        await dispatch(clearPromotion());
        var currentState = getState();
        const promotionValides = await api.post("promotion/getPromotionApplicableAuto", JSON.stringify({shop: currentState.shop}))
        if (Array.isArray(promotionValides)) {
            await promotionValides.forEach((promotionValide) => {
                dispatch(updatePromotions(promotionValide, false));
            });
        }
        let montantDepot = {montant: 0};

        for (let curItem of currentState.shop.cart) {
            if (curItem["type"].includes("Reservation")) {
                const depot = await api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: promotionValides}}));
                montantDepot.montant += depot.montant || 0;
                continue
            }
            montantDepot.montant += curItem["total"]
        }

        await dispatch({
            type: UPDATE_MONTANT_DEPOT, payload: montantDepot
        });

    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.message || err.response.statusText, status: err.response?.status || 500
            }
        });
        console.error(err.message || err.response.statusText);
    }
};

// Remove an item from the shopping cart
export const removeFromShoppingCart = (item, showAlert = true, lang = 'fr') => async (dispatch, getState) => {
    try {
        if (showAlert) {
            if (lang === 'en') dispatch(setAlert(`${ item.name.toUpperCase() } removed from shopping cart`, "danger")); else dispatch(setAlert(`${ item.name.toUpperCase() } enlevé du panier`, "danger"));
        }
        clearTimeout(timeoutId);
        dispatch({
            type: REMOVE_FROM_SHOPPING_CART, payload: item
        });

        var currentState = getState();
        //la condition est juste pour permettre l'ajout des activites dans le panier cela doit disparaitre une fois que les element de l'activite sont OK
        if (item["type"]?.includes("Reservation")) {
            dispatch(clearPromotion());
            if (!(item['selectedUnite'].type_hebergement === 'AC')) {
                const promotionValides = await api.post("promotion/getPromotionApplicableAuto", JSON.stringify({shop: currentState.shop}))
                if (Array.isArray(promotionValides)) {
                    promotionValides.forEach((promotionValide) => {
                        dispatch(updatePromotions(promotionValide));
                    });
                }
            }
        }

        let montantDepot = {montant: 0};

        for (let curItem of currentState.shop.cart) {
            if (curItem["type"].includes("Reservation")) {
                const depot = await api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: currentState.promotions}}));
                montantDepot.montant += depot.montant || 0;
                continue
            }
            montantDepot.montant += curItem["total"]
        }

        await dispatch({
            type: UPDATE_MONTANT_DEPOT, payload: montantDepot
        });

    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.response.statusText, status: err.response.status
            }
        });
    }
};
export const initialzePromotion = (promotion) => async (dispatch, getState) => {
    dispatch({
        type: INITIALIZE_PROMOTIONS, payload: promotion
    });
    const currentState = getState();
    if (currentState.shop.cart.some(item => item.type.includes("Reservation"))) {

        api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: promotion}})).then(depot => dispatch({
            type: UPDATE_MONTANT_DEPOT, payload: depot
        }));
    }
};

export const updatePromotions = (promotion, updateDepot = null) => async (dispatch, getState) => {
    if (!promotion.isValide) dispatch(setAlert(`Code invalide`, "danger")); else {
        await dispatch({
            type: UPDATE_PROMOTIONS, payload: promotion
        });

        if (updateDepot) {
            const currentState = getState();
            api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: currentState.shop.promotions}}))
                .then(depot => dispatch({
                    type: UPDATE_MONTANT_DEPOT, payload: depot
                }));
        }
    }
};

export const clearPromotion = () => async (dispacth) => dispacth({type: CLEAR_PROMOTION});

export const RecalculerShop = (shop) => async (dispatch) => dispatch({type: RECALCULER_SHOP})

export const removePromotions = (promotion) => async (dispatch, getState) => {
    await dispatch({
        type: REMOVE_PROMOTION, payload: promotion
    })
    const currentState = getState();
    const depot = await api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: currentState.shop.promotions}}));
    await dispatch({
        type: UPDATE_MONTANT_DEPOT, payload: depot
    });
};
export const setFraisAdmin = ({
                                  fixe, pourcent
                              }) => async (dispatch) => {
    try {
        dispatch({
            type: SET_FRAIS_ADMIN, fixe: fixe, pourcent: pourcent
        });
    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.response.statusText, status: err.response.status
            }
        });
    }
};

export const setTauxTaxes = ({
                                 tps, tvq, hebergement
                             }) => async (dispatch) => {
    try {
        dispatch({
            type: SET_TAUX_TAXES, tps: tps, tvq: tvq, hebergement: hebergement
        });
    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.response.statusText, status: err.response.status
            }
        });
    }
};

export const setTimeoutShop = (idsReservationTemp) => (dispatch) => {
    if (!idsReservationTemp) return;

    const actionTimeleft = 60000; // 1 minute for execution timeoutAction
    const alertTimeleft = 30000; // 30 sec left to alert that shop item will be deleted.
    const listeners = ["click", "mousemove", "keypress"];
    listeners.forEach((name) => window.addEventListener(name, resetTimeoutShop, true));

    let databaseTimer = Date.now();
    setSessionShopLoaded(true);
    resetTimeoutShop();

    function clearTimeoutShop() {
        listeners.forEach((name) => window.removeEventListener(name, resetTimeoutShop, true));
        setSessionShopLoaded(false);
        dispatch({type: CLEAR_TIMEOUT_SHOP});
        deleteReservationTemp(idsReservationTemp);
    }

    function resetTimeoutShop() {
        const timeleft = RESERVATION_TEMP_TIMEOUT - (Date.now() - databaseTimer);
        if (timeleft <= actionTimeleft && timeleft > alertTimeleft) {
            if (timeleft > alertTimeleft) {
                updateReservationTempTimeout(idsReservationTemp);
                databaseTimer = Date.now();
            } else {
                //console.log(`showing popup countdown from ${alertTimeleft / 1000} to 0 seconds.`);
                // show alert with countdown: shopping cart will be delete in {30} sec
            }
        }

        clearTimeout(timeoutId);
        timeoutId = setTimeout(clearTimeoutShop, RESERVATION_TEMP_TIMEOUT);
    }
};

// Updater an item from the shopping cart
export const updateShop = (index, newItem, showAlert = true, lang = 'fr') => async (dispatch, getState) => {
    try {
        if (newItem["type"].includes("Reservation")) {


            // Quels types de cart items ont 'Reservation' à part 'Reservation d'activité'?
            var montants = await calculateSumarryAmounts(newItem["selectedUnite"]);

            if (montants.montantDetailles.some(mntDetail => mntDetail.description === "Invalide")) throw new Error('Erreur lors du calcul du tarif')

            if (Object.keys(montants).length === 0) {
                newItem["fraisAdmin"] = 0;
                newItem["hebergement"] = 0;
                newItem["tps"] = newItem["montant"] * TPS;
                newItem["tvq"] = newItem["montant"] * TVQ;
                newItem["total"] = Number(newItem["montant"]) + Number(newItem["tps"]) + Number(newItem["tvq"]);
                newItem["montantDepots"] = newItem["total"];
                newItem["montantDetail"] = [];
            } else {
                newItem["montant"] = montants.montantAvantTaxes;
                newItem["fraisAdmin"] = montants.fraisAdmin;
                newItem["hebergement"] = montants.hebergement;
                newItem["tps"] = montants.tps;
                newItem["tvq"] = montants.tvq;
                newItem["total"] = montants.total;
                /*const montantDepot = montants.montantDepots.find((mntDepot) => mntDepot.no_depot === 1);
                newItem["montantDepots"] = montantDepot ? montantDepot.montant : montants.total;
                newItem["isMontantDepotFix"] = montantDepot ? montantDepot.is_depot_fix : false;*/
                newItem["montantDetail"] = montants.montantDetailles;
            }
        } else {
            if (newItem["tps"] === undefined || newItem["tvq"] === undefined || newItem["total"] === undefined || newItem["montantDepots"] === undefined || newItem["hebergement"] === undefined || newItem["fraisAdmin"] === 0) {
                // Bloc temporaire en attendant que le site calcule les taxes correctement partout
                newItem["tps"] = newItem["taxableTps"] || newItem["taxableTps"] === undefined ? newItem["montant"] * TPS : 0;
                //newItem['tvq'] = roundTwoDecimals(newItem['montant'] * TVQ + newItem['montant'] * TPS - newItem['tps']); // Ce "+ newItem['montant'] * TPS - newItem['tps']" corrige les erreurs d'arrondissement de cents (e.g. round(3.333 + 3.333) = 6.67 mais round(3.33) + round(3.33) = 6.66. Le Manisoft met la cet supplémentaire dans la TVQ).
                newItem["tvq"] = newItem["taxableTvq"] || newItem["taxableTvq"] === undefined ? newItem["montant"] * TVQ : 0;
                newItem["total"] = Number(newItem["montant"]) + Number(newItem["tps"]) + Number(newItem["tvq"]);
                newItem["fraisAdmin"] = 0;
                newItem["hebergement"] = 0;
                newItem["montantDepots"] = newItem["total"];
            }
        }
        // const res = await axios.get("/posts?sort=-tag,-updatedAt");
        /* if (newItem["tps"] === undefined || newItem["tvq"] === undefined || newItem["total"] === undefined) {
          // Bloc temporaire en attendant que le site calcule les taxes correctement partout
          newItem["tps"] = newItem["montant"] * TPS;
          newItem["tvq"] = newItem["montant"] * TVQ;
          newItem["total"] = Number(newItem["montant"]) + Number(newItem["tps"]) + Number(newItem["tvq"]);
        }*/

        if (showAlert) {
            if (lang === 'fr') dispatch(setAlert(newItem.name.toUpperCase() + " a été modifié avec succès", "success")); else dispatch(setAlert(newItem.name.toUpperCase() + " was successfully modified", "success"));
        }
        dispatch({
            type: UPDATE_SHOP, index: index, payload: newItem
        });
        await dispatch(clearPromotion());
        var currentState = getState();
        const promotionValides = await api.post("promotion/getPromotionApplicableAuto", JSON.stringify({shop: currentState.shop}))
        if (Array.isArray(promotionValides)) {
            await promotionValides.forEach((promotionValide) => {
                dispatch(updatePromotions(promotionValide, false));
            });
        }
        let montantDepot = {montant: 0};

        for (let curItem of currentState.shop.cart) {
            if (curItem["type"].includes("Reservation")) {
                const depot = await api.post("Reservation/MontantsDepot", JSON.stringify({shop: {...currentState.shop, promotions: promotionValides}}));
                montantDepot.montant += depot.montant || 0;
                continue
            }
            montantDepot.montant += curItem["total"]
        }

        await dispatch({
            type: UPDATE_MONTANT_DEPOT, payload: montantDepot
        });
    } catch (err) {
        dispatch({
            type: SHOP_ERROR, payload: {
                msg: err.response.statusText, status: err.response.status
            }
        });
    }
};

export const setErrorAlert = (message) => (dispatch) => {
    dispatch(setAlert(message, "danger"));
};


const getSessionIdsRsrvTemp = () => JSON.parse(sessionStorage.getItem(SessionKeys.IdS_RESERVATION_TEMP));

const getSessionShopLoaded = () => sessionStorage.getItem(SessionKeys.IS_SHOP_LOADED);

const setSessionIdsRsrvTemp = (idsResrvTempString) => sessionStorage.setItem(SessionKeys.IdS_RESERVATION_TEMP, idsResrvTempString);

const setSessionShopLoaded = (loaded) => sessionStorage.setItem(SessionKeys.IS_SHOP_LOADED, loaded);

const calculateTaxes = (array) => {
    array["tps"] = array["montant"] * TPS;
    array["tvq"] = array["montant"] * TVQ;
    array["total"] = array["montant"] + array["tps"] + array["tvq"];
    return array;
};